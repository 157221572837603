import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchTicketingStatus } from '../../Redux/actions';
import { Table, Label, Message } from 'semantic-ui-react';
import moment from 'moment';
import 'moment/locale/fi';
import { Spinner } from '../Common/Spinner';
import { T, getErrorMessage } from '../Common/Helpers';

class TicketStatus extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    this.reloadTicketStatus();
  }

  reloadTicketStatus() {
    const authKey = localStorage.getItem('x-auth-key');
    const { sortByColumn, direction } = this.state;
    const { activeWorkspace } = this.props;
    const { node_collection_id } = activeWorkspace;

    this.props.fetchTicketingStatus({
      authKey,
      ws_id: node_collection_id,
      sortByColumn,
      direction,
    });
  }

  renderTicketSales(event_status) {
    const {
      event_id,
      sell_status,
      ev_name,
      ev_event_start,
      ev_selling_stop,
      event_ticket_sales,
    } = event_status;

    const str_selling_start =
      ev_event_start && moment(ev_event_start).format('l HH:mm');
    const str_selling_stop =
      ev_selling_stop && moment(ev_selling_stop).format('l HH:mm');
    let sell_status_text = '';
    let statusState = {};
    if (sell_status == 'available') {
      sell_status_text = T('Available');
      statusState = { positive: true };
      if (ev_selling_stop) {
        sell_status_text += ` ${T('$Until_prefix')}${str_selling_stop}${T(
          '$until_postfix'
        )}`;
      }
    } else if (sell_status == 'waiting') {
      statusState.warning = true;
      sell_status_text = str_selling_start;
    } else if (sell_status == 'ended') {
      statusState.negative = true;
      sell_status_text = `${T('Ended')} ${str_selling_stop}`;
    }

    const event_start_date = moment(ev_event_start).format('l');
    return (
      <>
        <Table.Row>
          <Table.Cell colSpan={5} selectable>
            <Link to={`/app/events/match/edit/${event_id}`}>
              <Label color="grey" ribbon>
                {event_start_date} {ev_name}
              </Label>
            </Link>
          </Table.Cell>
        </Table.Row>

        {event_ticket_sales.map(ticket => {
          const strStockLevel =
            ticket.ticket_product_saldo === 0
              ? T('Sold Out')
              : ticket.ticket_product_saldo;
          return (
            <Table.Row>
              <Table.Cell>{ticket.ticket_product_name}</Table.Cell>
              <Table.Cell {...statusState}>{sell_status_text}</Table.Cell>
              <Table.Cell textAlign="right">{strStockLevel}</Table.Cell>
              <Table.Cell textAlign="right">{ticket.n_sold}</Table.Cell>
              <Table.Cell textAlign="right">{ticket.n_validated}</Table.Cell>
            </Table.Row>
          );
        })}
      </>
    );
  }

  render() {
    const { status_list } = this.props.ticketingStatus;
    if (this.props.ticketingFetchStatus === 'waiting' || !status_list) {
      return <Spinner />;
    }
    if (status_list.length === 0) {
      return (
        <Message
          info
          header={T('No Active Tickets Found')}
          list={[
            T('This page only shows ticket statistics for current events.'),
          ]}
        />
      );
    }
    return (
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{T('Ticket Name')}</Table.HeaderCell>
            <Table.HeaderCell>{T('Availability')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {T('Stock Level')}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right">{T('Sold')}</Table.HeaderCell>
            <Table.HeaderCell textAlign="right">
              {T('Validated')}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {status_list.map(event_status =>
            this.renderTicketSales(event_status)
          )}
        </Table.Body>
      </Table>
    );
  }
}

function mapStateToProps({ ticketing, auth, workspace }) {
  return {
    ticketingStatus: ticketing.ticketingStatus,
    ticketingFetchStatus: ticketing.ticketingStatusStatus,
    auth,
    workspace,
    activeWorkspace: workspace.workspaces[workspace.ws_id],
  };
}

export default connect(mapStateToProps, {
  fetchTicketingStatus,
})(TicketStatus);
